<template>
  <div :style="bgStyle" class="py-48 md:py-56">
    <div class="layout-container">
      <div class="text-center mobOnly:mb-48">
        <div v-if="block.Tagline" class="type-tag-xs mb-16">
          {{ block.Tagline }}
        </div>
        <WysiwygWrapper
          v-if="block.MainBody"
          :html="block.MainBody"
          style-config="wysiwyg text-center"
        />
      </div>
      <div
        :class="{
          'squareDesign': block.Layout === 'Square',
          'horizontalDesign': block.Layout !== 'Square'
        }"
      >
        <div class="mainImage">
          <img v-if="block.CenterImage?.Url" :src="block.CenterImage.Url">
        </div>
        <div
          v-for="(icon, iIndex) in block.IconBlocks"
          :key="`icon${iIndex}`"
        >
          <img
            v-if="icon.IconImage?.Url"
            :src="icon.IconImage?.Url"
            class="mb-16 max-w-[60%] md:max-w-auto"
          >
          <WysiwygWrapper
            v-if="icon.MainBody"
            :html="icon.MainBody"
            style-config="wysiwyg text-center"
          />
        </div>
      </div>
      <div
        v-if="block.PrimaryButton?.Text || block.SecondaryButton?.Text"
        class="flex justify-center items-center mt-48 gap-16"
      >
        <nuxt-link
          v-if="block.PrimaryButton"
          :to="block.PrimaryButton.LinkUrl"
          class="btn btn-primary"
        >
          {{ block.PrimaryButton.Text }}
        </nuxt-link>
        <nuxt-link
          v-if="block.SecondaryButton && block.SecondaryButton.LinkUrl"
          :to="block.SecondaryButton.LinkUrl"
          class="btn btn-basic"
        >
          {{ block.SecondaryButton.Text }}
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContentWithIconsBlockFragment } from '#gql';

const props = defineProps<{
  block: ContentWithIconsBlockFragment,
  isEditMode: boolean
}>();

const bgStyle = computed(()=> {
  if (props.block.BackgroundColor && props.block.BackgroundColor !== '') {
    return {
      'background': props.block.BackgroundColor,
    };
  }
  return {};
});

</script>

<style scoped lang="postcss">
.horizontalDesign {
  @apply flex flex-col gap-y-48 md:items-start md:flex-row md:justify-center md:gap-x-48 md:mt-48 md:mb-48;
  >div {
    @apply flex flex-col items-center justify-center md:basis-260;
  }
  .mainImage {
    @apply hidden;
  }
}
.squareDesign {
  @apply md:grid md:p-48;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  >div {
    @apply flex flex-col items-center justify-center;
  }

  .mainImage {
    grid-area: 1 / 2 / 3 / 3;
    @apply mobOnly:order-3;
  }
  >div:nth-child(2) {
    grid-area: 1 / 1 / 2 / 2;
    @apply mobOnly:order-1;
  }
  >div:nth-child(3) {
    grid-area: 2 / 1 / 3 / 2;
    @apply mobOnly:order-2;
  }
  >div:nth-child(4) {
    grid-area: 1 / 3 / 2 / 4;
    @apply mobOnly:order-4;
  }
  >div:nth-child(5) {
    grid-area: 2 / 3 / 3 / 4;
    @apply mobOnly:order-5;
  }

}

</style>
